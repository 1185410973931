import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SupplierManagementComponent } from "./supplier-management.component";
import { AddComponent } from "./add/add.component";
import { EditComponent } from "./edit/edit.component";






const routes: Routes = [
  {
    path: "",
    component: SupplierManagementComponent
  },
  {
    path: "add",
    component: AddComponent
  },
  {
    path: "edit",
    component: EditComponent
  },
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupplierRoutingModule {}
