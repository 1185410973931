import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { NotificationService } from '../../../services/notificationService';
import moment from 'moment';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrl: './add.component.scss'
})
export class AddComponent {
  project: any = {};
  categoryData: string[] = [];
  states: string[] = ['State 1', 'State 2', 'State 3'];
  supplierForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  supplierName:string[]=[];
  keyword1="productName";
  productName:any[]=[];
  id:any;
  categoryName:any;
  supplier:any[]=[];
  product:any[]=[];
  keyword="supplierName";
  isEnabled=true;
  constructor(private data: DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router
  ) {}

  ngOnInit()  {
    this.supplierForm= this.formBuilder.group({
      supplierName: ['',Validators.required],
      phone: ['', Validators.required],
      gstNo: ['',Validators.required],
      panNo: ['',Validators.required],
      contactPerson: ['', Validators.required],
      address: ['', Validators.required],
      bankName: ['', Validators.required],
      accountNo: ['', Validators.required],
      ifscNo: ['', Validators.required],
      landlineNo:['']
      
    });
  }

 

  goBack() {
    this.router.navigate(['/dashboard/supplier']);
  }
 
  get f() {
    return this.supplierForm.controls;
  }

  onSubmit(data: any) {
    if (this.supplierForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.postOn=moment().format()
    data.pendingAmount = 0;
    data.isActive = true;
    
    this.data.getCategoryById(this.id).subscribe((res)=>{
       console.log(res)
       res.supplierName = data.supplierName
       this.data.updateById(res,this.id).subscribe(()=>{})
    })
    this.data.create(data).subscribe((res:any)=>{
      console.log('Project Created!',res);
      this.toastr.showSuccess("Successfully!!","Project Added")
      this.router.navigate(['/dashboard/supplier'])
    })
  }
  selectEvent1(item:any) {
    // do something with selected item
    console.log(item)
    this.id = item.id
    this.supplierForm.patchValue({
      productName:item.productName,
      shortName:item.shortName
    })
  }
 
  onChangeSearch1(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getCategory().subscribe((val:any)=>{
      this.productName = Object.keys(val).map(key => ({ id: key, ...val[key] }));
      console.log(this.productName)
      return this.productName.filter(val => val.productName.toLowerCase().includes(search))
    })
  }

  onFocused1(e:any) {
    console.log(e)
    // do something
  }

  onCancel(){
    this.supplierForm.reset()
  }
  selectEvent(item:any) {
    // do something with selected item
    console.log(item)
    this.categoryData=[];
   this.data.getCategory().subscribe((res:any)=>{
    this.product = Object.keys(res).map(key => ({ id: key, ...res[key] }));
    this.product.forEach((res:any)=>{
    if (res.supplierName && res.supplierName.toLowerCase().trim() === item.supplierName.toLowerCase().trim()) {
      this.categoryData.push(res.productName)
      console.log(this.categoryData)
    }
  })
   })
   
  
   
    this.supplierForm.patchValue({
     
      shortName:item.shortName,
      supplierName:item.supplierName
    })
  }
 
  onChangeSearch(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getCategory().subscribe((res:any)=>{
      this.supplierName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
          console.log(this.supplierName)
        return this.supplierName.filter( val  =>   val.toLowerCase().includes(search));
    })    
  }

  onFocused(e:any) {
    console.log(e)
    // do something
  }

  enabled(){
    console.log(this.isEnabled)
    if(this.isEnabled === true){
    this.supplierForm.patchValue({
      sstatus:true
    })
  } else{
    this.supplierForm.patchValue({
      sstatus:false
    })
  }
  }
 
}
