<div class="container-fluid">
  <h3>Filters</h3>
    <div class="filters">
     
        <label for="supplier">Supplier Name</label>
        <select id="supplier" class="dropdown">
            <!-- Add options here -->
        </select>
        <label for="category">Category</label>
        
        <select id="category" class="dropdown">
            <!-- Add options here -->
        </select>
        <label for="location">Location</label>
       
        <select id="location" class="dropdown">
            <!-- Add options here -->
        </select>
        <button id="add-button" class="add-button" (click)="add()">Add +</button>
    </div>
    <div class="scroll">
      <table
  class="custom-table">
  <thead>
    <tr>
      
      <th>Supplier</th>
      <th>GST</th>
      <th>Contact Person</th>
      <th>Contact</th>
      <th>Pending Amount</th>
     
      <th>Action</th>
      </tr>
      </thead>
      <tbody >
          <tr
          *ngFor="
          let element of supplierList
          | paginate: { itemsPerPage: 25, currentPage: page }
        "
          >
              
            <!-- <td>{{element.productName}}</td>
            <td>{{element.shortName}}</td> -->
            <td>{{element.supplierName}}</td>
            <td>{{element.gstNo}}</td>
            <td>{{element.contactPerson}}</td>
          
            <td>{{element.phone}}</td>
            <td>{{element.pendingAmount}} </td>
          
            <td>
              
              <mat-icon (click)="onEdit(element.id)">edit</mat-icon>
              <mat-icon (click)="onDelete(element.id)" >delete</mat-icon>
            </td>
          </tr>
          </tbody>
          </table>
   </div>
</div>