<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>New Supplier</h2>
    <form [formGroup]="supplierForm" >
      <div class="form-row">
        <!-- <div class="form-group col-sm-12 col-md-4">
          <label for="productName"> Category<span class="requried-field">*</span></label>
          <ng-autocomplete
            [data]="productName"
            [searchKeyword]="keyword1"
            placeholder="Enter the productName"
            (selected)='selectEvent1($event)'
            (inputChanged)='onChangeSearch1($event)'
            (inputFocused)='onFocused1($event)'
            historyIdentifier="productName"
            [itemTemplate]="itemTemplate1"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
        
          <ng-template #itemTemplate1 let-item>
            <a [innerHTML]="item.productName"></a>
          </ng-template>
        
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
          <div *ngIf="submitted && f['productName'].errors" class="invalid-feedback">
            <div *ngIf="f['productName'].errors['required']">Category is required</div>
          </div>
        </div> -->
        <div class="form-group col-sm-12 col-md-4">
          <label for="supplierName"> Supplier Name<span class="requried-field">*</span></label>
          <input
          type="text"
          id="supplierName"
          placeholder="Enter SupplierName"
          class="form-control"
          formControlName="supplierName"
          [ngClass]="{ 'is-invalid': submitted && f['supplierName'].errors }"
        />
        </div>
        <!-- <div class="form-group col-sm-12 col-md-4">
          <label for="shortName">Short Name</label>
          <input
            type="text"
            id="shortName"
            placeholder="Enter shortName"
            class="form-control"
            formControlName="shortName"
            [ngClass]="{ 'is-invalid': submitted && f['shortName'].errors }"
          />
          <div *ngIf="submitted && f['shortName'].errors" class="invalid-feedback">
            <div *ngIf="f['shortName'].errors['required']">Short Name is required</div>
            
          </div>
        </div> -->
        <div class="form-group col-sm-12 col-md-4">
          <label for="phone">Phone<span class="requried-field">*</span></label>
          <input
            type="text"
            id="phone"
            placeholder="Enter phone"
            class="form-control"
            formControlName="phone"
            [ngClass]="{ 'is-invalid': submitted && f['phone'].errors }"
          />
          <div *ngIf="submitted && f['phone'].errors" class="invalid-feedback">
            <div *ngIf="f['phone'].errors['required']">Phone is required</div>
          </div>
        </div>
  
        <div class="form-group col-sm-12 col-md-4">
          <label for="contactPerson">Contact Person</label>
          <input
            type="text"
            id="contactPerson"
            placeholder="Enter contactPerson"
            class="form-control"
            formControlName="contactPerson"
            [ngClass]="{ 'is-invalid': submitted && f['contactPerson'].errors }"
          />
          <div *ngIf="submitted && f['contactPerson'].errors" class="invalid-feedback">
            <div *ngIf="f['contactPerson'].errors['contactPerson']">Contact is required</div>
            
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-4">
          <label for="gstNo">GST No</label>
          <input
            type="text"
            id="gstNo"
            placeholder="Enter gstNo"
            class="form-control"
            formControlName="gstNo"
            [ngClass]="{ 'is-invalid': submitted && f['gstNo'].errors }"
          />
          <div *ngIf="submitted && f['gstNo'].errors" class="invalid-feedback">
            <div *ngIf="f['gstNo'].errors['required']">GST No is required</div>
            
          </div>
        </div>
  
        <div class="form-group col-sm-12 col-md-4">
          <label for="panNo"> Pan No <span class="requried-field">*</span></label>
          <input
            type="text"
            id="panNo"
            placeholder="Enter panNo"
            class="form-control"
            formControlName="panNo"
            [ngClass]="{ 'is-invalid': submitted && f['panNo'].errors }"
          />
          <div *ngIf="submitted && f['panNo'].errors" class="invalid-feedback">
            <div *ngIf="f['panNo'].errors['required']">Pan No is required</div>
          
          </div>
        </div>
        <!-- <div class="form-group col-sm-12 col-md-12 col-lg-4">
          <label for="sstatus">Status<span class="requried-field">*</span></label>
          <mat-slide-toggle class="slide-enable" [color]="isEnabled ? ' rgba(0, 128, 0, 0.5) ' : 'default'" (change)="enabled()">Enable</mat-slide-toggle>
        </div> -->
      </div>
  
      <h3>Details</h3>
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-4">
          <label for="address">Address<span class="requried-field">*</span></label>
          <textarea
            type="text"
            id="address"
            placeholder="Enter address"
            class="form-control"
            formControlName="address"
            [ngClass]="{ 'is-invalid': submitted && f['address'].errors }"
          ></textarea>
          <div *ngIf="submitted && f['address'].errors" class="invalid-feedback">
            <div *ngIf="f['address'].errors['required']">Address is required</div>
          </div>
        </div>
  
        <div class="form-group col-sm-12 col-md-4">
          <label for="bankName">Bank Name<span class="requried-field">*</span></label>
          <input
            type="text"
            id="bankName"
            placeholder="Enter bankName"
            class="form-control"
            formControlName="bankName"
            [ngClass]="{ 'is-invalid': submitted && f['bankName'].errors }"
          />
          <div *ngIf="submitted && f['bankName'].errors" class="invalid-feedback">
            <div *ngIf="f['bankName'].errors['required']">Bank Name is required</div>
          </div>
        </div>
  
        <div class="form-group col-sm-12 col-md-4">
          <label for="accountNo">Account No<span class="requried-field">*</span></label>
          <input
            type="text"
            id="accountNo"
            placeholder="accountNo"
            class="form-control"
            formControlName="accountNo"
            [ngClass]="{ 'is-invalid': submitted && f['accountNo'].errors }"
          />
          <div *ngIf="submitted && f['accountNo'].errors" class="invalid-feedback">
            <div *ngIf="f['accountNo'].errors['required']">Account No is required</div>
          </div>
        </div>
  
  
        <div class="form-group col-sm-12 col-md-4">
          <label for="ifscNo">IFSC<span class="requried-field">*</span></label>
          <input
            type="text"
            id="ifscNo"
            placeholder="Enter ifscNo"
            class="form-control"
            formControlName="ifscNo"
            [ngClass]="{ 'is-invalid': submitted && f['ifscNo'].errors }"
          />
          <div *ngIf="submitted && f['ifscNo'].errors" class="invalid-feedback">
            <div *ngIf="f['ifscNo'].errors['required']">IFSC is required</div>
          </div>
        </div>      
      </div>
      <div class="formbutton">

     
      <button type="submit" class="btn btn-primary" (click)="onSubmit(supplierForm.value)">Submit</button>
      <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
    </div>
    </form>
  </div>
  